import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { Role, UserPermissions } from './Users/permissionsUtils'

type Response = { users: User[] }

export type User = {
  organizationId: string
  id: string
  email: string
  name: string
  profileImage: {
    key: string
    type: string
  } | null
  title: string
  role: Role
  inviteStatus: 'unknown' | 'accepted' | 'sent'
  team: {
    uid: string
    name: string
  } | null
  mostRecentConversation: Date | null
  permissions: Partial<UserPermissions>
  externalUid: string
  isCapturiUser: boolean
  lastTokenRefresh: null | Date
  isDeleted: boolean
  externalUids: string[]
  teamLeadTeams:
    | {
        uid: string
        name: string
      }[]
    | null
}

const isNonDeletedUsersSelector = (data: Response): User[] => {
  return data.users.filter((u) => !u.isDeleted)
}
export const useUsers = (
  organizationUid: string | undefined,
): UseQueryResult<User[], ResponseError> =>
  useQuery({
    enabled: !!organizationUid,
    queryKey: ['superpowers', 'users', organizationUid],
    queryFn: () =>
      request.get<Response>(
        `authentication/superpowers/users/${organizationUid}?includeDeleted=true`,
      ),
    select: isNonDeletedUsersSelector,
  })

const isCapturiUsersSelector = (data: Response): User[] => {
  return data.users.filter((u) => u.isCapturiUser)
}
const isActiveCapturiUsersSelector = (data: Response): User[] => {
  return data.users.filter(
    (u) => u.isCapturiUser && u.inviteStatus === 'accepted',
  )
}

export const useCapturiUsers = (
  organizationUid: string | undefined,
): UseQueryResult<User[], ResponseError> =>
  useQuery({
    enabled: !!organizationUid,
    queryKey: ['superpowers', 'users', organizationUid],
    queryFn: () =>
      request.get<Response>(
        `authentication/superpowers/users/${organizationUid}?includeDeleted=true`,
      ),
    select: isCapturiUsersSelector,
  })
export const useActiveCapturiUsers = (
  organizationUid: string | undefined,
): UseQueryResult<User[], ResponseError> =>
  useQuery({
    enabled: !!organizationUid,
    queryKey: ['superpowers', 'users', organizationUid],
    queryFn: () =>
      request.get<Response>(
        `authentication/superpowers/users/${organizationUid}?includeDeleted=true`,
      ),
    select: isActiveCapturiUsersSelector,
  })

type PatchPayload = {
  name: string
  title: string
  role: Role
  email: string
  permissions: Partial<UserPermissions>
}
type UpdateUser = {
  organizationUid: string
  userUid: string
  payload: PatchPayload
}
export const useUpdateUser = (): UseMutationResult<
  Response,
  ResponseError,
  UpdateUser,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ organizationUid, userUid, payload }) =>
      request.patch(
        `authentication/superpowers/users/${organizationUid}/${userUid}`,
        { json: payload },
      ),
    onSuccess: (_, { organizationUid, payload, userUid }) => {
      queryClient.setQueryData<Response>(
        ['superpowers', 'users', organizationUid],
        (oldData) => {
          if (!oldData) {
            return undefined
          }

          return {
            users: oldData.users.map((d) =>
              d.id === userUid ? { ...d, ...payload } : d,
            ),
          }
        },
      )
    },
  })
}

type AddAccessUserPayload = {
  name: string
  title: string
  email: string
  reason: string
  ttl: Date | null
}
type AddUser = {
  organizationUid: string
  payload: AddAccessUserPayload
}

export const useAddAccessUser = (): UseMutationResult<
  User,
  ResponseError,
  AddUser,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ organizationUid, payload }) =>
      request.post<User>(
        `authentication/superpowers/${organizationUid}/access`,
        {
          json: payload,
        },
      ),
    onSuccess: (response, { organizationUid }) => {
      //Updates the users org list
      queryClient.invalidateQueries({
        queryKey: ['me', 'organizations'],
      })
      queryClient.setQueryData<Response>(
        ['superpowers', 'users', organizationUid],
        (oldData) => {
          if (!oldData) {
            return undefined
          }

          return {
            users: [response, ...oldData.users],
          }
        },
      )
    },
  })
}
