import { Card, CardBody, CardHeader, Heading, Text } from '@chakra-ui/react'

const padTokens = (x: number): string => x.toString().padStart(2, '0')
const formatTime = (date: Date): string => {
  const ms = (Date.now() - date.getTime()) / 1000
  const minutes = Math.floor(ms / 60) % 60
  const seconds = Math.floor(ms) % 60

  if (minutes) return `${padTokens(minutes)}m${padTokens(seconds)}s`
  return `${seconds}s`
}

type Props = {
  shouldRestart: true
  integrationKey: string
  integrationType: string
  organizationUid: string
  lastRunStarted: null | Date
  lastRunEnded: null | Date
  color: string
}

export const ItemCard: React.FC<Props> = ({
  integrationKey,
  lastRunEnded,
  lastRunStarted,
  color,
}) => {
  const isRunning = !!lastRunStarted

  return (
    <Card
      variant={isRunning ? 'filled' : 'elevated'}
      bg={isRunning ? 'green.100' : undefined}
      size="sm"
      mb={isRunning ? '12' : '4'}
      borderLeft={`solid 3px ${color}`}
    >
      <CardHeader display="flex" justifyContent="space-between">
        <Heading size="xl">{integrationKey}</Heading>
      </CardHeader>
      <CardBody>
        {isRunning && <Text>Is Running: {formatTime(lastRunStarted)}</Text>}
        {lastRunEnded && <Text>Time in queue: {formatTime(lastRunEnded)}</Text>}
      </CardBody>
    </Card>
  )
}
