import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

type Props = { integrationKey: string } & BaseModalComponentProps
type File = {
  date: Date
  file: string
  size: number
}

const mapData = (files: File[]): string =>
  files
    .map(
      ({ date, file, size }) =>
        `${date.toLocaleString('da-DK')}  ${file}   ${size}`,
    )
    .reverse()
    .join('\n')

const FilesOnFtpModal: React.FC<Props> = ({ onClose, integrationKey }) => {
  const { data, isLoading, error } = useQuery<File[], ResponseError, string>({
    queryKey: ['superpowers', 'integrations', 'puzzel', integrationKey, 'ftp'],
    queryFn: async () =>
      request.get(`superpowers/integration/puzzel/${integrationKey}/files`),
    select: mapData,
  })

  return (
    <Modal isOpen onClose={onClose} size="6xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Files on puzzel ftp</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            {error && <Text>{error.message}</Text>}
            {isLoading && <Spinner />}
            <Text
              overflowY="auto"
              fontFamily="monospace"
              whiteSpace="break-spaces"
            >
              {data}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button primary onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default FilesOnFtpModal
