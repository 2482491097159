import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

export type ScimLog = {
  Id: string
  OrganizationUid: string
  CapturiLog: string
  CustomerLog: string
  CreatedAt: Date
  Level: string
  Action: string
  Target: string
}

export const useScimLogs = (
  organizationUid: string,
): UseQueryResult<ScimLog[] | null, ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'scim', organizationUid, 'logs'],
    queryFn: async () => {
      const result = await request.get<ScimLog[]>(
        `superpowers/scim/monitoring/${organizationUid}`,
      )

      return result || []
    },
  })

export const useCreateScimLogToken = (
  organizationUid: string,
): UseMutationResult<{ token: string }, ResponseError, null, ResponseError> => {
  return useMutation({
    mutationFn: () =>
      request.post(`superpowers/scim/monitoring/${organizationUid}/link`),
  })
}
