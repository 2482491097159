import { Grid } from '@chakra-ui/react'
import React from 'react'
import { CurrentlyRunningColumn } from './Column'

const integrations = [
  {
    name: 'Adversus v2',
    type: 'adversus',
    color: '#3639a4',
  },
] as const

export const CurrentlyRunning: React.FC = () => {
  return (
    <Grid
      templateColumns={{ sm: '1fr', lg: '1fr 1fr 1fr' }}
      width="100%"
      gap="6"
      mt="8"
    >
      {integrations.map((i) => (
        <CurrentlyRunningColumn key={i.type} {...i} />
      ))}
    </Grid>
  )
}
