import { Button, List, ListItem } from '@capturi/ui-components'
import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import React from 'react'
import { useLocalStorage } from 'react-use'

import { useModal } from '@capturi/use-modal'
import GeneratePuzzelTestData from '../Organization/Integration/puzzel/GeneratePuzzelTestData'
import { Title } from '../utils'
import UserPrivileges from './Roleme'

const Options: React.FC = () => {
  const [showLogEvent, setShowLogEvent] =
    useLocalStorage<boolean>('showLogEvents')
  const [openGenerateTestData] = useModal(GeneratePuzzelTestData)

  return (
    <>
      <Title mb="8">Options</Title>
      <Button
        colorScheme="pink"
        onClick={() => {
          openGenerateTestData()
        }}
      >
        Generate Puzzel test data
      </Button>

      <List mt="4">
        <ListItem hasDivider>
          <FormControl display="flex" alignItems="center">
            <Switch
              size="sm"
              color="primary"
              isChecked={showLogEvent}
              onChange={() => setShowLogEvent(!showLogEvent)}
              mr={2}
            />
            <FormLabel htmlFor="showLogEvents" fontWeight="normal" m={0}>
              showLogEvents - show logEvent in console
            </FormLabel>
          </FormControl>
        </ListItem>
        <ListItem hasDivider>
          <UserPrivileges />
        </ListItem>
      </List>
    </>
  )
}

export default Options
